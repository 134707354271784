import React from 'react';
import Layout from '../../components/Layout';
import graphqlclient from '../../core/graphqlclient';
import Login from './Login';

const title = 'Inloggen';

async function action({ data, req, query }) {
  const { isloaded } = data;
  let { user, messages, destination } = data;

  if (!isloaded) {
    const newdata = await graphqlclient(`
      query LoginPage {
        me { displayname }
      }
    `, null, req);

    user = newdata.me;
  }

  if (user) {
    return {
      redirect: '/profiel',
    };
  }

  // Check login error messages.
  if (req && req.session && req.session.messages) {
    messages = req.session.messages;
    delete req.session.messages;
  }
  if (req && req.session.loginDestination) {
    destination = req.session.loginDestination;
  }

  // Check query.
  if (query && query.destination && query.destination.indexOf('//') === -1) {
    destination = query.destination;
  }

  return {
    title,
    chunks: ['login'],
    component:
  <Layout user={user}>
    <Login messages={messages} destination={destination} />
  </Layout>,
    data: {
      user,
      messages,
      isloaded: true,
      destination,
    },
  };
}

export default action;
