import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Login.css';
import SimpleHeader from '../../components/Header/SimpleHeader';
import LoginControl from '../../components/Login';

const title = 'Inloggen';

class Login extends React.Component {
  static propTypes = {
    messages: PropTypes.arrayOf(PropTypes.string),
    destination: PropTypes.string,
  };

  render() {
    const { destination, messages } = this.props;
    return (
      <div>
        <SimpleHeader title={title} />
        <div className={s.content}>
          <LoginControl messages={messages} destination={destination} />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Login);
